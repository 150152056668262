<template>
  <offer-modal-wrapper>
    <template v-slot:offer>
      <subscribe-to-use />

      <p class="text-2xl font-semibold">
        {{ $t('state.trial_period_is_over') }}
      </p>

      <p>
        {{ $t('state.sell_analytics_in_free_trial') }}
      </p>

      <div class="pt-4">
        <main-features />
      </div>
      
      <the-deadline />
    </template>
    <template v-slot:action>
      <router-link
        class="btn btn-lg btn-primary w-full mt-4"
        :to="{ name: 'plans' }"
        @click.prevent="$emit('closeOffer')"
      >
        {{ $t('action.subscribe_now') }}
      </router-link>
    </template>
  </offer-modal-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OfferModalWrapper from './OfferModalWrapper.vue'
import TheDeadline from './TheDeadline.vue'
import SubscribeToUse from './SubscribeToUse.vue'
import MainFeatures from './MainFeatures.vue'

export default defineComponent({
  components: { OfferModalWrapper, TheDeadline, SubscribeToUse, MainFeatures }
})
</script>
