
import { defineComponent } from 'vue'
import OfferModalWrapper from './OfferModalWrapper.vue'
import TheDeadline from './TheDeadline.vue'
import SubscribeToUse from './SubscribeToUse.vue'
import MainFeatures from './MainFeatures.vue'

export default defineComponent({
  components: { OfferModalWrapper, TheDeadline, SubscribeToUse, MainFeatures }
})
